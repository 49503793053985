import { FileAddOutlined, PaperClipOutlined } from '@ant-design/icons';
import {
    Typography,
    Input,
    Space,
    List,
    Flex,
    Button,
    Upload,
    UploadFile,
} from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';

import { ListProfileDto, ProfileNoteDto } from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { ActionButton } from '../../action-button';
import useCurrentProfile from '../../hooks/use-current-profile/use-current-profile';
import { useSelection } from '../../hooks/use-selection';

const NoteListItem = ({
    item,
    isEditing,
    isAuthor,
    fetchProfileNotes,
    setIsEditing,
}: {
    item: ProfileNoteDto;
    isEditing: boolean;
    isAuthor: boolean;
    fetchProfileNotes: () => Promise<void>;
    setIsEditing: (bool: boolean) => void;
}) => {
    const api = useApiClient();
    const [isLoading, setIsLoading] = useState(false);
    const [editNote, setEditNote] = useState(item.note);
    const [removeFiles, setRemoveFiles] = useState<UploadFile[]>([]);
    const [addFiles, setAddFiles] = useState<UploadFile[]>([]);
    const [editNoteFileList, setEditNoteFileList] = useState<UploadFile[]>([]);

    useEffect(() => {
        setEditNoteFileList(
            item.attachments.map((attachment) => ({
                uid: attachment.id,
                url: attachment.url,
                name: attachment.fileName,
            }))
        );
        setAddFiles([]);
        setRemoveFiles([]);
    }, [item]);

    return (
        <List.Item>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Flex justify="space-between">
                    <div>
                        <b>{item.author}</b>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        {item.createdAt.toLocaleDateString()}
                        {item.createdAt.getTime() < item.updatedAt.getTime() ? (
                            <>
                                <br />
                                <i>
                                    (edited at{' '}
                                    {DateTime.fromJSDate(
                                        item.updatedAt
                                    ).toLocaleString(DateTime.DATETIME_SHORT)}
                                    )
                                </i>
                            </>
                        ) : null}
                    </div>
                </Flex>

                {isEditing ? (
                    <>
                        <Input.TextArea
                            defaultValue={item.note}
                            autoSize={true}
                            autoFocus={true}
                            disabled={isLoading}
                            onChange={(evt) => {
                                setEditNote(evt.target.value);
                            }}
                        />
                        <div>
                            <ActionButton
                                size="small"
                                successMessage="Note updated"
                                action={async () => {
                                    if (!api) {
                                        return;
                                    }
                                    setIsLoading(true);
                                    await api.profileNoteControllerUpdateNote({
                                        noteId: item.id,
                                        profileNoteCreateDto: {
                                            note: editNote,
                                        },
                                    });

                                    if (addFiles.length > 0) {
                                        await api?.profileNoteControllerUploadAttachments(
                                            {
                                                noteId: item.id,
                                                attachments:
                                                    addFiles as unknown as File[],
                                            }
                                        );
                                    }

                                    if (removeFiles.length > 0) {
                                        await Promise.all(
                                            removeFiles.map(
                                                async (file) =>
                                                    api?.profileNoteControllerDeleteAttachment(
                                                        {
                                                            noteId: item.id,
                                                            attachmentId:
                                                                file.uid,
                                                        }
                                                    )
                                            )
                                        );
                                    }

                                    await fetchProfileNotes();

                                    setIsEditing(false);
                                    setIsLoading(false);
                                }}
                            >
                                Save
                            </ActionButton>{' '}
                            <Button
                                size="small"
                                onClick={() => setIsEditing(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </>
                ) : (
                    <p style={{ whiteSpace: 'pre-wrap' }}>{item.note}</p>
                )}
                {item.attachments.length > 0 && (
                    <>
                        {isEditing ? (
                            <Upload
                                onRemove={(file: UploadFile) => {
                                    const index =
                                        editNoteFileList.indexOf(file);

                                    const newFileList =
                                        editNoteFileList.slice();
                                    newFileList.splice(index, 1);
                                    setEditNoteFileList(newFileList);

                                    setRemoveFiles([...removeFiles, file]);
                                }}
                                beforeUpload={(file) => {
                                    setEditNoteFileList([
                                        ...editNoteFileList,
                                        file,
                                    ]);

                                    setAddFiles([...addFiles, file]);

                                    return false;
                                }}
                                fileList={editNoteFileList}
                            >
                                <Button icon={<FileAddOutlined />} size="small">
                                    Attach File
                                </Button>
                            </Upload>
                        ) : (
                            item.attachments.map((attachment) => (
                                <div key={attachment.id}>
                                    <PaperClipOutlined
                                        style={{ marginRight: 5 }}
                                    />
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={attachment.url}
                                    >
                                        {attachment.fileName}
                                    </a>
                                </div>
                            ))
                        )}
                    </>
                )}

                {isAuthor && !isEditing && (
                    <div>
                        <a role="button" onClick={() => setIsEditing(true)}>
                            Edit
                        </a>
                        <ActionButton
                            successMessage="Note deleted"
                            type="link"
                            confirm="Are you sure you want to delete this note?"
                            action={async () => {
                                await api?.profileNoteControllerDeleteNote({
                                    noteId: item.id,
                                });
                                await fetchProfileNotes();
                            }}
                        >
                            Delete
                        </ActionButton>
                    </div>
                )}
            </Space>
        </List.Item>
    );
};

export default function NotesTab() {
    const api = useApiClient();
    const { selection } = useSelection<ListProfileDto>();

    const [isLoading, setIsLoading] = useState(false);
    const [draftNote, setDraftNote] = useState<string>('');
    const notesRef = useRef<HTMLFormElement>(null);
    const [toggleEdit, setToggleEdit] = useState<Record<string, boolean>>({});
    const [notes, setNotes] = useState<ProfileNoteDto[]>();
    const [draftNoteFileList, setDraftNoteFileList] = useState<UploadFile[]>(
        []
    );

    const currentProfile = useCurrentProfile();

    const fetchProfileNotes = async () => {
        setIsLoading(true);
        const result = await api?.profileNoteControllerGetNotes({
            profileId: selection.id,
        });

        setNotes(result);
        setIsLoading(false);
    };

    useEffect(() => {
        void fetchProfileNotes();
    }, [api]);

    if (!api) {
        return null;
    }
    return (
        <>
            <Typography.Title level={4}>Notes</Typography.Title>
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <Input.TextArea
                    ref={notesRef}
                    rows={4}
                    disabled={isLoading}
                    onChange={(evt) => setDraftNote(evt.target.value)}
                />
                <Flex justify="space-between">
                    <ActionButton
                        successMessage="Note submitted"
                        disabled={isLoading}
                        action={async () => {
                            if (
                                !draftNote.length &&
                                !draftNoteFileList.length
                            ) {
                                throw Error('No note drafted');
                            }

                            const saved =
                                await api?.profileNoteControllerCreateNote({
                                    profileId: selection.id,
                                    profileNoteCreateDto: {
                                        note: draftNote,
                                    },
                                });

                            if (draftNoteFileList.length > 0) {
                                await api.profileNoteControllerUploadAttachments(
                                    {
                                        noteId: saved.id,
                                        attachments:
                                            draftNoteFileList as unknown as File[],
                                    }
                                );
                            }

                            await fetchProfileNotes();

                            if (notesRef?.current) {
                                notesRef.current.value = '';
                                setDraftNote('');
                                setDraftNoteFileList([]);
                            }
                        }}
                    >
                        Submit
                    </ActionButton>
                    <Upload
                        listType="picture"
                        onRemove={(file) => {
                            const index = draftNoteFileList.indexOf(file);
                            const newFileList = [...draftNoteFileList];
                            newFileList.splice(index, 1);
                            setDraftNoteFileList(newFileList);
                        }}
                        beforeUpload={(file) => {
                            setDraftNoteFileList([...draftNoteFileList, file]);

                            return false;
                        }}
                        fileList={draftNoteFileList}
                    >
                        <Button icon={<FileAddOutlined />}>Attach File</Button>
                    </Upload>
                </Flex>
                {notes && (
                    <List
                        bordered
                        dataSource={notes}
                        renderItem={(item) => (
                            <NoteListItem
                                item={item}
                                isAuthor={
                                    currentProfile.profile?.id === item.authorId
                                }
                                isEditing={!!toggleEdit[item.id]}
                                fetchProfileNotes={() => fetchProfileNotes()}
                                setIsEditing={(bool: boolean) => {
                                    setToggleEdit({
                                        ...toggleEdit,
                                        [item.id]: bool,
                                    });
                                }}
                            />
                        )}
                    />
                )}
            </Space>
        </>
    );
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-row-selected {
    background: #0e3e66;
    color: #fff;
}

.saasquatch-logo {
    width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".table-row-selected {\n    background: #0e3e66;\n    color: #fff;\n}\n\n.saasquatch-logo {\n    width: 200px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {
    TableColumnType as AntColumn,
    TableColumnGroupType as AntColumnGroup,
    Table as AntTable,
    TableProps as AntTableProps,
    Row,
} from 'antd';
import startCase from 'lodash/startCase';
import React, { useEffect, useState } from 'react';
import { useMatches } from 'react-router';

import { unwrapError } from '@clh/ui';

import { DisplayValue } from '../display-value';
import { useApiCache } from '../hooks/use-api-cache';
import FetchRecordProvider from '../hooks/use-fetch-record/fetch-record-provider';
import SelectionProvider from '../hooks/use-selection/selection-provider';
import { useToast } from '../hooks/use-toast';

import Details from './details';
import { FilterOptions, Filters } from './filters';
import { PaginatedResult } from './paginated-result';

type AntColumnTypes<RecordType> =
    | AntColumn<RecordType>
    | AntColumnGroup<RecordType>;

export type Column<RecordType> = AntColumnTypes<RecordType> | string;

type TableProps<RecordType> = {
    getRecords: (
        params?: object
    ) => Promise<RecordType[]> | Promise<PaginatedResult<RecordType[]>>;
    getRecordById?: (id: string) => Promise<RecordType>;
    columns: Column<RecordType>[];
    buttons?: React.ReactNode[];
    children?: React.ReactNode;
    filters?: FilterOptions;
    cacheKey: string;
    paginated?: boolean;
} & Omit<AntTableProps<RecordType>, 'columns'>;

/**
 * A wrapper around antd's <Table> element that provides facilities for fetching rows.
 *
 * @param props - Antd table props with record fetching API call as well as:
 *
 *  - `getRecords`: the fetch call for retrieving records.
 *  - `columns`: Array of antd Table.Column props or just a string name of the column.
 *
 * @returns Table component
 */
export function FetchRecordTable<RecordType extends object>(
    props: TableProps<RecordType>
) {
    const [columns, setColumns] = useState<AntColumnTypes<RecordType>[]>();
    const { notification } = useToast();

    const getRecords = props.paginated
        ? (params?: object) => props.getRecords({ count: true, ...params })
        : props.getRecords;

    const { loading, record, fetchRecord, error, params, setParams, meta } =
        useApiCache(getRecords, props.cacheKey);

    useEffect(() => {
        setColumns(
            props.columns.map((col) => {
                if (typeof col === 'string') {
                    return {
                        title: startCase(col),
                        key: col,
                        dataIndex: col,
                        render: (val) => <DisplayValue value={val} />,
                    };
                }

                return col;
            })
        );
    }, [props.columns]);

    useEffect(() => {
        if (error) {
            notification.error({ message: unwrapError(error), duration: 3 });
        }
    }, [error]);

    return (
        <FetchRecordProvider
            record={record}
            loading={loading}
            meta={meta}
            fetchRecord={fetchRecord}
            params={params}
            setParams={setParams}
            error={error}
        >
            {(props.buttons || props.filters) && (
                <Row
                    style={{
                        paddingBottom: 10,
                        justifyContent: props.filters
                            ? 'space-between'
                            : 'flex-end',
                        flexWrap: 'wrap',
                        gap: 10,
                    }}
                >
                    {props.filters && <Filters {...props.filters} />}
                    {props.buttons && (
                        <div>{props.buttons.map((button) => button)}</div>
                    )}
                </Row>
            )}
            <AntTable
                rowKey="id"
                bordered
                pagination={
                    meta
                        ? {
                              total: meta.totalCount,
                              current: params?.['page'],
                              onChange: (page, pageSize) => {
                                  const newParams = {
                                      ...params,
                                      page,
                                      pageSize,
                                  };
                                  setParams(newParams);
                                  fetchRecord(newParams).catch((e) =>
                                      // eslint-disable-next-line no-console
                                      console.error(e)
                                  );
                              },
                          }
                        : undefined
                }
                {...props}
                dataSource={record}
                columns={columns}
                loading={loading}
            />
            {props.children}
        </FetchRecordProvider>
    );
}

/**
 * Stores row selection in the selection context
 *
 * @param props FetchRecordTable props
 *
 * @returns Table component
 */
export function SelectRecordTable<RecordType extends object>(
    props: TableProps<RecordType>
) {
    const [selection, setSelection] = useState<RecordType>();

    const matches = useMatches();
    const { notification } = useToast();

    if (matches[0].params?.selectionId && props.getRecordById) {
        props
            .getRecordById(matches[0].params.selectionId)
            .then((record) => setSelection(record))
            .catch((error) => {
                notification.error({
                    message: unwrapError(error),
                    duration: 3,
                });
            });
    }

    return (
        <SelectionProvider selection={selection} setSelection={setSelection}>
            <FetchRecordTable
                {...props}
                rowClassName={(record: RecordType) =>
                    record === selection ? 'table-row-selected' : ''
                }
                onRow={(record: RecordType) => {
                    return {
                        onClick: () => {
                            setSelection(record);
                        },
                    };
                }}
            />
            {props.children}
        </SelectionProvider>
    );
}

/**
 * Provides a detail "drawer" to drill down into a row's detail.
 *
 * @param props Children elements will be injected into the drawer element
 *
 * @returns Table component
 */
export function DrilldownTable<RecordType extends object>({
    children,
    ...props
}: TableProps<RecordType> & {
    children: React.ReactNode;
}) {
    return (
        <SelectRecordTable {...props}>
            <Details>{children}</Details>
        </SelectRecordTable>
    );
}

import { EditFilled } from '@ant-design/icons';
import { Typography, Table, Alert, InputNumber, Space, Button } from 'antd';
import { DateTime } from 'luxon';
import { useState } from 'react';

import { ShiftListItemDto, ProfileDto, ShiftStatus } from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { ActionButton } from '../../action-button';
import { useSelection } from '../../hooks/use-selection';
import ObjectPropertyList from '../../record-details/object-property-list';

const Text = Typography.Text;

const LogTime = ({
    minutesLogged,
    hasQueuedPayment,
    close,
}: {
    minutesLogged?: number;
    hasQueuedPayment: boolean;
    close: () => void;
}) => {
    const { selection, setSelection } = useSelection<ShiftListItemDto>();
    const api = useApiClient();

    const [minutesWorked, setMinutesWorked] = useState<number | null>(null);

    if (!selection.nurseProfile) {
        return null;
    }

    return (
        <Space direction="vertical" style={{ width: '300px' }}>
            Enter minutes worked:
            <InputNumber
                min={1}
                max={1440}
                step={15}
                defaultValue={minutesLogged}
                onChange={(time) => setMinutesWorked(time)}
            />
            <Space direction="horizontal">
                <ActionButton
                    disabled={!minutesWorked || minutesWorked === 0}
                    successMessage="Time submitted"
                    action={async () => {
                        if (!minutesWorked) {
                            throw Error('Invalid minutes worked entered');
                        }
                        if (hasQueuedPayment) {
                            await api?.shiftControllerUpdateShift({
                                shiftId: selection.id,
                                shiftUpdateDto: {
                                    minutesWorked,
                                },
                            });
                        } else {
                            await api?.shiftControllerSubmitTimeWorked({
                                shiftId: selection.id,
                                shiftTimeWorkedDto: {
                                    minutesWorked,
                                },
                            });
                        }

                        const updated = await api?.shiftControllerViewShift({
                            shiftId: selection.id,
                        });

                        setSelection({
                            ...selection,
                            zealEmployeeCheckId:
                                updated?.zealEmployeeCheckId ?? 'Pending',
                            minutesWorked,
                        });
                        close();
                    }}
                >
                    {minutesLogged ? 'Update Time' : 'Submit Time'}
                </ActionButton>
                <Button onClick={close}>Cancel</Button>
            </Space>
            <Alert
                message={
                    hasQueuedPayment
                        ? `Updating this time will not affect any payments already queued to the employee and will not queue any new payments. `
                        : `Submitting time will queue payment to the assigned nurse (${selection.nurseProfile.firstName} ${selection.nurseProfile.lastName})`
                }
                type="warning"
            />
        </Space>
    );
};

const EditPayRate = ({
    payRate: defaultPayRate,
    close,
}: {
    payRate: number;
    close: () => void;
}) => {
    const { selection, setSelection } = useSelection<ShiftListItemDto>();
    const api = useApiClient();

    const [payRate, setPayRate] = useState<number | null>(defaultPayRate / 100);

    return (
        <Space direction="vertical" style={{ width: '300px' }}>
            Enter pay rate:
            <InputNumber
                min={20}
                value={payRate}
                onChange={(payRate) => setPayRate(payRate)}
            />
            <Space direction="horizontal">
                <ActionButton
                    disabled={!payRate || payRate === 0}
                    successMessage="Time submitted"
                    action={async () => {
                        if (!payRate) {
                            throw Error('Invalid pay rate entered');
                        }

                        await api?.shiftControllerUpdateShift({
                            shiftId: selection.id,
                            shiftUpdateDto: {
                                payRate: payRate * 100,
                            },
                        });

                        setSelection({
                            ...selection,
                            payRate: payRate * 100,
                        });
                        close();
                    }}
                >
                    Update Pay Rate
                </ActionButton>
                <Button onClick={close}>Cancel</Button>
            </Space>
        </Space>
    );
};

export default function AssignmentTab() {
    const { selection } = useSelection<ShiftListItemDto>();
    const [editMinutesWorked, setEditMinutesWorked] = useState<boolean>(false);
    const [editPayRate, setEditPayRate] = useState<boolean>(false);

    return (
        <Space direction="vertical" style={{ width: '100%' }} size="large">
            <ObjectPropertyList
                values={selection}
                fields={{
                    nurseProfile: (nurseProfile: ProfileDto) =>
                        nurseProfile && (
                            <>
                                {nurseProfile.firstName} {nurseProfile.lastName}
                            </>
                        ),
                    payRate: (payRate: number) =>
                        payRate > 0 ? (
                            editPayRate ? (
                                <EditPayRate
                                    payRate={selection.payRate}
                                    close={() => setEditPayRate(false)}
                                />
                            ) : (
                                <div>
                                    ${(payRate / 100).toFixed(2)}
                                    <a onClick={() => setEditPayRate(true)}>
                                        <EditFilled />
                                    </a>
                                </div>
                            )
                        ) : (
                            <>0</>
                        ),
                    minutesWorked: (minutes?: number) => (
                        <div>
                            {selection.nurseProfile && !editMinutesWorked ? (
                                <>
                                    {minutes && `${minutes}`}
                                    <a
                                        onClick={() =>
                                            setEditMinutesWorked(
                                                !editMinutesWorked
                                            )
                                        }
                                    >
                                        {minutes ? (
                                            <EditFilled />
                                        ) : (
                                            'Submit Time'
                                        )}
                                    </a>
                                </>
                            ) : null}

                            {editMinutesWorked && (
                                <LogTime
                                    hasQueuedPayment={
                                        !!selection.zealEmployeeCheckId
                                    }
                                    minutesLogged={minutes}
                                    close={() => setEditMinutesWorked(false)}
                                />
                            )}
                        </div>
                    ),
                    stripeInvoiceId: selection.minutesWorked
                        ? (stripeInvoiceId: string) => (
                              <>
                                  {stripeInvoiceId ? (
                                      stripeInvoiceId
                                  ) : (
                                      <Text italic>Not yet invoiced</Text>
                                  )}
                              </>
                          )
                        : undefined,
                    zealEmployeeCheckId: selection.minutesWorked
                        ? (zealEmployeeCheckId: string) => (
                              <>
                                  {zealEmployeeCheckId ? (
                                      zealEmployeeCheckId
                                  ) : (
                                      <Text italic>Payment not yet queued</Text>
                                  )}
                              </>
                          )
                        : undefined,
                }}
            />

            {selection.status === ShiftStatus.Canceled && (
                <>
                    <Typography.Title level={4}>
                        Cancellation Details
                    </Typography.Title>

                    <ObjectPropertyList
                        values={{
                            date: selection.cancellationDate,
                            canceledBy: selection.canceledByProfile,
                            reason: selection.cancellationReason,
                            comment: selection.cancellationComment,
                        }}
                        fields="*"
                    />
                </>
            )}

            {selection.cancellations.length > 0 && (
                <>
                    <Typography.Title level={4}>
                        Nurse Cancellations
                    </Typography.Title>

                    <Table
                        rowKey="id"
                        bordered
                        dataSource={selection.cancellations}
                        columns={[
                            {
                                dataIndex: 'nurseProfile',
                                title: 'Nurse',
                                render: (nurseProfile) => (
                                    <>
                                        {nurseProfile.firstName}{' '}
                                        {nurseProfile.lastName}
                                    </>
                                ),
                            },
                            {
                                dataIndex: 'createdAt',
                                title: 'Canceled at',
                                render: (createdAt: Date) => (
                                    <>
                                        {DateTime.fromJSDate(
                                            createdAt
                                        ).toLocaleString(
                                            DateTime.DATETIME_SHORT
                                        )}
                                    </>
                                ),
                            },
                            { dataIndex: 'reason', title: 'Reason' },
                            { dataIndex: 'comment', title: 'Comment' },
                        ]}
                    />
                </>
            )}
        </Space>
    );
}

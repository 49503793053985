import { Card as AntCard, Col, Layout, Row } from 'antd';
import { useEffect } from 'react';

import { Header } from '../layout';

import linksJson from './links.json';

const links = linksJson as Array<{
    title: string;
    url: string;
}>;

const { Meta } = AntCard;

const host = (url: string) => {
    const uri = new URL(url);

    return uri.host;
};

export default function Profiles() {
    useEffect(() => {
        links.sort((a, b) => a.title.localeCompare(b.title));
    }, []);
    return (
        <Layout>
            <Header>
                <h2>Humla Health</h2>
            </Header>
            <Row gutter={[32, 32]} style={{ padding: 32, marginRight: 0 }}>
                {links.map((link) => (
                    <Col span={6} key={link.title}>
                        <a href={link.url}>
                            <AntCard hoverable>
                                <Meta
                                    title={link.title}
                                    description={host(link.url)}
                                />
                            </AntCard>
                        </a>
                    </Col>
                ))}
            </Row>
        </Layout>
    );
}

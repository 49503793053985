import { Space, Typography } from 'antd';

import { FacilityDto, ShiftListItemDto } from '@clh/api-client';
import {
    getShiftDuration,
    getTextFromQuillDeltas,
    toDateISOTimeStr,
} from '@clh/util';

import { getDateStrWithTimezone } from '../../helpers';
import { useSelection } from '../../hooks/use-selection';
import ObjectPropertyList from '../../record-details/object-property-list';

const Text = Typography.Text;

export default function DetailsTab() {
    const { selection } = useSelection<ShiftListItemDto>();

    const { timezone } = selection.facility.address;

    return (
        <>
            <Typography.Title level={4}>Details</Typography.Title>
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                <ObjectPropertyList
                    values={{
                        ...selection,
                        hours: getShiftDuration(
                            selection.shiftTime,
                            toDateISOTimeStr(selection.startTime),
                            toDateISOTimeStr(selection.endTime)
                        ),
                    }}
                    fields={{
                        id: (id: string) => (
                            <Text code={true} copyable={true}>
                                {id}
                            </Text>
                        ),
                        facility: (facility: FacilityDto) => (
                            <>{facility.name}</>
                        ),
                        startTime: (startTime: Date) => (
                            <>{getDateStrWithTimezone(startTime, timezone)}</>
                        ),
                        endTime: (endTime: Date) => (
                            <>{getDateStrWithTimezone(endTime, timezone)}</>
                        ),
                        shiftTime: true,
                        hours: true,
                        shiftType: true,
                        specialtyCode: true,
                        licenseType: true,
                        description: (description) => (
                            <>{getTextFromQuillDeltas(description)}</>
                        ),
                    }}
                />
            </Space>
        </>
    );
}

import {
    Tabs as AntTabs,
    TabsProps as AntTabsProps,
    Space,
    Typography,
} from 'antd';

import { FacilityDto } from '@clh/api-client';

import { useSelection } from '../../hooks/use-selection';

import { DangerTab } from './danger';
import InfoTab from './info';
import MembersTab from './members';
import NursesTab from './nurses';

export default function Tabs() {
    const { selection } = useSelection<FacilityDto>();

    const tabs: AntTabsProps['items'] = [
        {
            label: 'Info',
            key: 'tab-info',
            children: <InfoTab />,
        },
        {
            label: 'Members',
            key: 'tab-nurse-profile',
            children: <MembersTab />,
        },
        {
            label: 'Nurses',
            key: 'tab-nurses',
            children: <NursesTab />,
        },
        {
            label: 'Danger',
            key: 'tab-danger',
            children: <DangerTab />,
        },
    ];

    return (
        <>
            <Space
                direction="vertical"
                align="center"
                style={{ width: '100%' }}
            >
                <Typography.Title
                    level={3}
                >{`${selection.name}`}</Typography.Title>
            </Space>
            <AntTabs items={tabs} />
        </>
    );
}

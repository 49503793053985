import { createBrowserRouter } from 'react-router-dom';

import AppChrome from './app';
import Facilities from './facilities/facilities';
import ErrorPage from './failure-modes/error-page';
import NotFoundPage from './failure-modes/not-found';
import UnauthorizedPage from './failure-modes/unauthorized-page';
import { Jurisdictions } from './jurisdictions';
import { Landing } from './landing';
import Logout from './logout/logout';
import { NurseLicenseCompacts } from './nurse-license-compacts';
import { PlatformAdmins } from './platform-admins';
import { Profiles } from './profiles';
import { Shifts } from './shifts';
import { TestData } from './test-data';

const children = [
    {
        index: true,
        path: '/',
        element: <Landing />,
    },
    {
        path: '/profiles',
        element: <Profiles />,
        children: [
            {
                path: ':selectionId',
            },
        ],
    },
    {
        path: '/facilities',
        element: <Facilities />,
    },
    {
        path: '/shifts',
        element: <Shifts />,
    },
    {
        path: '/jurisdictions',
        element: <Jurisdictions />,
    },
    {
        path: '/compacts',
        element: <NurseLicenseCompacts />,
    },
    {
        path: '/platform-admins',
        element: <PlatformAdmins />,
    },
    {
        path: '/logout',
        element: <Logout />,
    },
];

if (ENV_CONFIG_VARS.ENVIRONMENT_NAME !== 'prod') {
    children.push({
        path: '/test-data',
        element: <TestData />,
    });
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <AppChrome />,
        errorElement: <ErrorPage />,
        children,
    },
    {
        path: '/unauthorized',
        element: <UnauthorizedPage />,
        errorElement: <UnauthorizedPage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
]);

export default router;

import { CheckCircleFilled } from '@ant-design/icons';
import { Select, Space, Tooltip, Typography } from 'antd';
import { DateTime } from 'luxon';
import { PropsWithChildren, useState } from 'react';

import {
    CertificationDto,
    CertificationVerificationStatus,
    InfectiousDiseaseTestType,
    ListProfileDto,
    VaccinationDto,
} from '@clh/api-client';

import { ActionButton } from '../../action-button';
import { useApiCache } from '../../hooks/use-api-cache';
import { ApiClient, useApiClient } from '../../hooks/use-api-client';
import { useSelection } from '../../hooks/use-selection';
import ObjectPropertyList from '../../record-details/object-property-list';
import { FetchRecordTable } from '../../table/table';

const Title = ({ children }: PropsWithChildren) => (
    <Typography.Title level={4}>{children}</Typography.Title>
);

interface SectionProps {
    api: ApiClient;
    profileId: string;
}

const EditCertificationVerificationStatus = ({
    code,
    profileId,
    currentStatus,
    verificationDate,
}: {
    code: string;
    profileId: string;
    currentStatus: CertificationVerificationStatus;
    verificationDate?: Date | null;
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [verificationStatus, setVerificationStatus] =
        useState<CertificationVerificationStatus>();
    const api = useApiClient();

    if (!isEditing) {
        return (
            <>
                {currentStatus === CertificationVerificationStatus.Verified &&
                verificationDate ? (
                    <Tooltip
                        title={`Verified on ${DateTime.fromJSDate(
                            verificationDate
                        ).toLocaleString(DateTime.DATETIME_SHORT)}`}
                    >
                        {currentStatus}
                    </Tooltip>
                ) : (
                    currentStatus
                )}{' '}
                <a onClick={() => setIsEditing(!isEditing)}>Change</a>
            </>
        );
    }

    return (
        <>
            <Select
                style={{ width: '50%' }}
                options={Object.entries(CertificationVerificationStatus).map(
                    (vals) => ({ label: vals[0], value: vals[1] })
                )}
                defaultValue={currentStatus}
                onChange={(status: CertificationVerificationStatus) => {
                    setVerificationStatus(status);
                }}
            />
            <ActionButton
                action={async () => {
                    if (verificationStatus) {
                        await api?.certificationControllerSetVerificationStatus(
                            {
                                profileId,
                                code,
                                status: verificationStatus,
                            }
                        );
                        setIsEditing(false);
                    }
                }}
                disabled={!verificationStatus}
                successMessage="Certification verification status updated"
            >
                Save
            </ActionButton>
        </>
    );
};

const Certifications = ({ api, profileId }: SectionProps) => {
    const { selection } = useSelection<ListProfileDto>();

    return (
        <div>
            <Title>Certifications</Title>
            <FetchRecordTable
                size="small"
                rowKey="code"
                pagination={false}
                getRecords={() =>
                    api
                        .certificationControllerGetCertifications({
                            profileId,
                        })
                        .then((c) => c.filter((c) => !!c.expiration))
                }
                cacheKey={
                    'certificationControllerGetCertifications' + profileId
                }
                columns={[
                    'code',
                    'expiration',
                    {
                        title: 'Card',
                        dataIndex: 'certificationCard',
                        render: (card) =>
                            card && (
                                <a
                                    href={card.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Download
                                </a>
                            ),
                    },
                    {
                        title: 'Status',
                        dataIndex: 'verificationStatus',
                        render: (
                            status: CertificationVerificationStatus,
                            row: CertificationDto
                        ) => {
                            if (row.ahaECardCode || row.rqiCertificateCode) {
                                return (
                                    <>
                                        {row.ahaECardCode ? 'AHA eCard' : 'RQI'}{' '}
                                        Verified
                                        {row.holderName !==
                                        `${selection.firstName} ${selection.lastName}` ? (
                                            <>
                                                <br />
                                                <CheckCircleFilled
                                                    style={{
                                                        color: 'green',
                                                    }}
                                                />{' '}
                                                Nurse confirmed name mismatch
                                            </>
                                        ) : null}
                                    </>
                                );
                            } else if (row.verificationStatus) {
                                return (
                                    <EditCertificationVerificationStatus
                                        profileId={profileId}
                                        code={row.code}
                                        currentStatus={status}
                                        verificationDate={row.verificationDate}
                                    />
                                );
                            }

                            return 'N/A';
                        },
                    },
                ]}
            />
        </div>
    );
};

const Contracts = ({ api, profileId }: SectionProps) => {
    const { record: confidentiality, setRecord: setConfidentiality } =
        useApiCache(
            () =>
                api.nurseControllerGetConfidentialityAgreement({
                    profileId,
                }),
            'nurseControllerGetConfidentialityAgreement' + profileId
        );
    const { record: contract, setRecord: setContract } = useApiCache(
        () =>
            api.nurseControllerGetContract({
                profileId,
            }),
        'nurseControllerGetContract' + profileId
    );

    const fields = {
        createdAt: true as const,
        signature: (sig: string) => (
            <>{sig && <img src={sig} style={{ width: 220 }} />}</>
        ),
    };

    return (
        <div>
            <Title>Contracts</Title>
            <b>Offer Letter Agreement</b>
            {contract?.createdAt ? (
                <div style={{ marginBottom: 10 }}>
                    <ObjectPropertyList values={contract} fields={fields} />
                    <ActionButton
                        successMessage="Offer letter reset"
                        action={async () => {
                            await api.nurseControllerResetContract({
                                profileId,
                            });
                            setContract((x) =>
                                x
                                    ? {
                                          ...x,
                                          createdAt: null,
                                          signature: null,
                                      }
                                    : x
                            );
                        }}
                    >
                        Reset Offer Letter
                    </ActionButton>
                </div>
            ) : (
                <p>
                    <i>Not yet signed</i>
                </p>
            )}
            <b>Confidentiality Agreement</b>
            {confidentiality?.createdAt ? (
                <div>
                    <ObjectPropertyList
                        values={confidentiality}
                        fields={fields}
                    />
                    <ActionButton
                        successMessage="Confidentiality agreement reset"
                        action={async () => {
                            await api.nurseControllerResetConfidentialityAgreement(
                                {
                                    profileId,
                                }
                            );
                            setConfidentiality((x) =>
                                x
                                    ? {
                                          ...x,
                                          createdAt: null,
                                          signature: null,
                                      }
                                    : x
                            );
                        }}
                    >
                        Reset Confidentiality Agreement
                    </ActionButton>
                </div>
            ) : (
                <p>
                    <i>Not yet signed</i>
                </p>
            )}
        </div>
    );
};

const Licenses = ({ api, profileId }: SectionProps) => (
    <div>
        <Title>Licenses</Title>
        <FetchRecordTable
            size="small"
            pagination={false}
            getRecords={() =>
                api.nurseControllerGetLicenses({
                    profileId,
                })
            }
            cacheKey={'nurseControllerGetLicenses' + profileId}
            columns={[
                {
                    title: 'License #',
                    dataIndex: 'licenseNumber',
                },
                'expirationDate',
                'state',
                {
                    title: 'Type',
                    dataIndex: 'licenseType',
                },
                'status',
            ]}
        />
    </div>
);

const Vaccinations = ({ api, profileId }: SectionProps) => (
    <div>
        <Title>Vaccinations</Title>
        <FetchRecordTable
            size="small"
            pagination={false}
            getRecords={() =>
                api.vaccinationControllerGetVaccinations({
                    profileId,
                })
            }
            cacheKey={'vaccinationControllerGetVaccinations' + profileId}
            columns={[
                'code',
                'declinationDate',
                {
                    title: 'Titer Date',
                    render: (v: VaccinationDto) =>
                        v.hasTiter ? (
                            DateTime.fromJSDate(v.doses[0].date).toFormat(
                                'MM/dd/yyyy'
                            )
                        ) : (
                            <i>N/A</i>
                        ),
                },
                {
                    title: 'Doses',
                    render: (v: VaccinationDto) => (
                        <>
                            {!v.hasTiter && v.doses.length ? (
                                <ol
                                    style={{
                                        marginBottom: 0,
                                        paddingLeft: 12,
                                    }}
                                >
                                    {v.doses.map((dose, i) => (
                                        <li key={i}>
                                            Date:{' '}
                                            {DateTime.fromJSDate(
                                                dose.date
                                            ).toFormat('MM/dd/yyyy')}
                                            {dose.manufacturer && (
                                                <>
                                                    <br />
                                                    Manufacturer:{' '}
                                                    {dose.manufacturer}
                                                </>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            ) : (
                                <i>N/A</i>
                            )}
                        </>
                    ),
                },
            ]}
        />
    </div>
);

const TuberculosisTest = ({ api, profileId }: SectionProps) => {
    const { record: tbTest } = useApiCache(
        () =>
            api.infectiousDiseaseTestControllerGetTest({
                code: InfectiousDiseaseTestType.Mantoux,
                profileId,
            }),
        'infectiousDiseaseTestControllerGetTest' + profileId
    );

    return (
        <div>
            <Title>Tuberculosis Test</Title>

            {tbTest ? (
                <ObjectPropertyList
                    values={{
                        ...tbTest,
                        testDocument: tbTest.testCardV2?.href,
                    }}
                    fields={{
                        testDate: true,
                        testDocument: (testDocument) =>
                            testDocument && (
                                <a
                                    href={testDocument}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    View Test
                                </a>
                            ),
                    }}
                />
            ) : (
                <i>(none)</i>
            )}
        </div>
    );
};

const TuberculosisScreening = ({ api, profileId }: SectionProps) => {
    const { record: tbScreening } = useApiCache(
        () =>
            api.tuberculosisScreeningControllerGetResponses({
                profileId,
            }),
        'tuberculosisScreeningControllerGetResponses' + profileId
    );

    return (
        <div>
            <Title>Annual Tuberculosis Screening</Title>

            {tbScreening ? (
                <ObjectPropertyList
                    values={{
                        ...tbScreening,
                        symptoms: tbScreening.symptoms.map(
                            (s) => s.name + ': ' + (s.answer ? 'Yes' : 'No')
                        ),
                        workHistoryQuestions:
                            tbScreening.workHistoryQuestions.map(
                                (w) =>
                                    w.question +
                                    ': ' +
                                    (w.answer ? 'Yes' : 'No')
                            ),
                        expirationDate: tbScreening.expiresAt,
                    }}
                    fields={{
                        screeningDate: true,
                        expirationDate: true,
                        symptoms: true,
                        workHistoryQuestions: true,
                        signature: (sig: string) => (
                            <>
                                {sig && (
                                    <img src={sig} style={{ width: 220 }} />
                                )}
                            </>
                        ),
                    }}
                />
            ) : (
                <i>(none)</i>
            )}
        </div>
    );
};

export default function NurseProfileTab() {
    const api = useApiClient();
    const { selection } = useSelection<ListProfileDto>();

    if (!api) {
        return null;
    }

    const props = { api, profileId: selection.id };

    return (
        <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Licenses {...props} />
            <Certifications {...props} />
            <Contracts {...props} />
            <Vaccinations {...props} />
            <TuberculosisTest {...props} />
            <TuberculosisScreening {...props} />
        </Space>
    );
}

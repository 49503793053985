var _a, _b, _c;
import { MaskType, VaccinationManufacturer, VaccinationType, } from '@clh/api-client';
export var VACCINATION_TYPE_DISPLAY_NAMES = (_a = {},
    _a[VaccinationType.Covid19] = 'Covid-19',
    _a[VaccinationType.Influenza] = 'Influenza (Flu)',
    _a[VaccinationType.MeaslesMumpsRubella] = 'MMR (Measles, Mumps, Rubella)',
    _a[VaccinationType.Varicella] = 'Varicella (Chickenpox)',
    _a[VaccinationType.Hepatitis] = 'Hepatitis B',
    _a[VaccinationType.Tdap] = 'Tdap',
    _a);
export var VACCINATION_MANUFACTURER_DISPLAY_NAMES = (_b = {},
    _b[VaccinationManufacturer.JohnsonAndJohnson] = 'Johnson & Johnson',
    _b[VaccinationManufacturer.Moderna] = 'Moderna',
    _b[VaccinationManufacturer.Novavax] = 'Novavax',
    _b[VaccinationManufacturer.PfizerBiotech] = 'Pfizer-BioNTech',
    _b[VaccinationManufacturer.Other] = 'Other',
    _b);
export var MASK_TYPE_DISPLAY_NAMES = (_c = {},
    _c[MaskType.ThreeM1870Plus] = '3M 1870+',
    _c[MaskType.ThreeM1860] = '3M 1860',
    _c[MaskType.ThreeM1860S] = '3M 1860-S',
    _c[MaskType.Other] = 'Other',
    _c);
